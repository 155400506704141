







































import { defineComponent,PropType, computed, onMounted, ref } from '@vue/composition-api';
import Uploader from "@/components/uploader.vue"
import ElInputPlus from '@/components/el-input-plus.vue'
import Dictionary from '@/common/dictionary';


// 下拉框配置项
type SelectOptions = {
  label:string,
  value:string
}[]

export type TableFormOptions = {
  /** 表头以及字段配置 */
  column:FormField[],
  /** 是否显示添加按钮  */
  addable?:boolean,
  /** 是否显示移除按钮 */
  removeable?:boolean
}


export type  FormField<T = {[key:string]:any}> = {
  required?:boolean,
  key:keyof T,
  /**
   * text 文本输入
   * number 数字输入
   */
  type:'text' | 'number' | 'integer' | 'float'|  'sms' | 'textarea' | 'select' | 'file' | 'title' | 'category' | 'table-form',
  /**
   * 默认值
   */
  value?:any,
  label?:string,
  /**
   * 单个 form-item label 宽度设置
   * */
  labelWidth?:string,
  options?:SelectOptions  | TableFormOptions | Dictionary,
  placeholder?:string,
  /**
   * 发送短信验证码的接口
   */
  smsApi?:string,
  /**
   * 输入框后缀内容（当前仅支持字符串）
   */
  append?:string,
  disabled?:boolean,
}





export default defineComponent({
    name:"ElFormPlusField",
    components:{ Uploader, ElInputPlus },
    props:{
      field:String,
      required:{
        type:Boolean
      },
      append:String,
      smsApi:{
        type:String
      },
      /**
       * 当type为 float时传入 小数精度
       */
      precision:{
        type:Number,
        default:2,
      },
      readyonly:{
        type:Boolean
      },
      type:{
        type:String as PropType<FormField['type']>,
        default:'text'
      },
      label:{
        type:String
      },
      options:{
        type:[Array ,Object]
      },
      placeholder:{
        type:String,
      },
      disabled:{
        type:Boolean
      },
      value:{
        type:[String,Number,Object,Array],
      }
    },
    setup(props,ctx) {
        const val = computed({
          get(){
            return props.value
          },
          set(val){
             ctx.emit("input",val)
          }
        })
        // const tbaleFormValue = ref([])
        onMounted(async ()=>{
          const {value,type,label} = props;
          if(props.options instanceof Dictionary){
             await props.options.init()
          }
          if(type==='table-form'){
            // console.log(`ElFormPlusField-Mounted   TableForm`,{value,type,label});
          }
          
        })



        const getSelectOptions:()=>SelectOptions  = ()=>{
          if(props.options instanceof Dictionary){
             return props.options.parseSelectOptions()
          }
          return props.options as SelectOptions
        }
        const getTableFormColumn:()=>TableFormOptions['column'] = ()=>{
          return (props.options as TableFormOptions).column
        }
        const tableFormColumn =  computed(()=>{
            return (props.options as TableFormOptions).column
        }) 
        return {val,getSelectOptions,getTableFormColumn,tableFormColumn}
        
    },
})
