import { $post } from "@/http/axios";
import { getStorage, setStorage } from "./utils";
export enum DICTIONARY_NAME{
    /** @desc 产业基金申报状态 */
    INDUSTRY_FUND_DECLARE_STATUS = 'industry_fund_declare_status',
    /** 产业类别 */
    INDUSTRY_CATEGORY = 'industry_category',
    /** 空间资源-申请状态 */
    ENT_APPLY_STATUS = 'ent_apply_status',
    /** 空间资源-租赁状态 */
    ENT_LEASE_STATUS = 'ent_lease_status',
    /** 空间资源-发布状态 */
    ENT_PUBLISH_STATUS = 'ent_publish_status',
    /** 政策申请进度-工单状态 */
    INST_STS = 'inst_sts'
}
export  type DICT = {[key:number | string ]:string}
type DICT_STORE = {[key in DICTIONARY_NAME ]:DICT} | {}
/**
 * 字典源数据格式
 */
type DictRwaData = {
     /** @desc 字典名称 */
     dictDesc:string,
     /** @desc 字典项描述文字 */
     itemDesc:string,
     /** @desc 字典项值 */
     itemValue:string
}[]
/** @desc 异步字典工具类 */
export default class Dictionary{
    name:DICTIONARY_NAME
    dict?:DICT = {}
    constructor(name:DICTIONARY_NAME){
        this.name = name
        this.init();
    }
    parseSelectOptions(){
        return Object.entries(this.dict!).map(([value,label])=>({label,value}))
    }
    async  loadDictionaryRawData(name:DICTIONARY_NAME){
        const res = await $post(`/dict/list?dictKey=${String(name)}&selectType=1`);
        const raw:DictRwaData = res.result;
        // console.log(`获取字典源数据`,raw,JSON.stringify(raw));
        return Object.fromEntries(raw.map(item=>([item.itemValue,item.itemDesc])))
        // return result.reduce((dic:Object,item:DictionaryItem)=>{
        //   return {...dic,[item.itemValue]:item.itemDesc}
        // },{})
    }
    async init(){
        let dict:DICT = this.getDictStorage() || await this.loadDictionaryRawData(this.name);
        this.dict = dict
        if(dict){
            this.saveToStorage(dict)
        }
    }
    get(key:string){
        // console.log("字典取值",this.dict)
        return this.dict && this.dict![key] ?this.dict![key] :  '-'
    }
    /**
     * 对接TablePlusColumn 的formatter 
     */
    formatter(val:any){
        return this.get(val)
    }
    //保存到缓存
    saveToStorage(dict:DICT){
        let dictionary:DICT_STORE = getStorage("DICTIONARY") || {}
        dictionary[this.name] = dict
        setStorage("DICTIONARY",dictionary)
    }
    getDictStorage(){
        let dictionary:DICT_STORE = getStorage("DICTIONARY") || {}
        // console.log(`字典从缓存获取-[${this.name}]`,dictionary[this.name])
        return dictionary[this.name]
    }

}

