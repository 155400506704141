





































import { defineComponent, ref,PropType, watch, onMounted, watchEffect } from '@vue/composition-api';
import Uploader from "@/components/uploader.vue"
import ElFormPlusField, { FormField, TableFormOptions } from "./field.vue"

/**表单结构 */
export type FormStructureItem<T = {[key:string]:any}> = {
  rule?:any[],
  span?:number, //表单项所占列宽  一行分24等分 默认值12  两项占一行
  disabled?:boolean
} & FormField<T>



export default defineComponent({
    name:"ElFormPlus",
    components:{Uploader,ElFormPlusField},
    props:{
      init:{
        type:Function as PropType<()=>any>,
      },
      labelWidth:{
        type:String,
        default:'auto'
      },
      labelPosition:{
          type:String as PropType<'left' | 'top' | 'right'>,
          default:'left'
      },
      hideFooter:{
        type:Boolean
      },
      readyonly:{
          type:Boolean
      },
      confirmText:{
        type:String,
        default:'提交'
      },
      cancelText:{
        type:String,
        default:'取消'
      },
      /** 表单结构 */
      structure:{
        required:true,
        type:Array as PropType<FormStructureItem[]>
      },
      value:{
        type:Object,
        default:()=>({})
      }
    },
    setup(props,ctx) {
        const formRef = ref()
        let formData = ref<{[key:string]:any}>({})
        const defaultValueDic:{[key in  FormField['type']]:any}= {text:'',number:'',float:'',integer:'',textarea:'',select:'',file:[],title:'',sms:'',category:'','table-form':[]}
        const initFormData = async ()=>{
            /** 默认值 */
            const inputValue = props.value;
            /**
             * 生成表格表单默认值
             */
            const generationTableFormDefaultValue = (options:TableFormOptions)=>{
              return  [Object.fromEntries(options.column.map(col=>([col.key,defaultValueDic[col.type]])))]
             
              // Object.fromEntries(options.column.map(item=>([item.key,defaultValueDic[item.type]])))

            }
            // console.log('传入值',inputValue)
            const structure = props.structure
            formData.value  = Object.fromEntries(structure.map(item=>[item.key as string,inputValue[item.key] ? inputValue[item.key] :(
              item.type === 'table-form' ?   generationTableFormDefaultValue(item.options as TableFormOptions) :  defaultValueDic[item.type]
            )]))
            
            if(props.init && props.init instanceof Function){
             let initData =  await props.init();
              console.log(`表单初始化内部`,{initData})
              // formData.value = initData;
            }
           
            ctx.emit('ready',ctx.root)
        }
        onMounted(async ()=>{
          initFormData();
    
        })
        watchEffect((nv)=>{
          console.log(`外部值更新`,props.value);
          formData.value   = props.value;
          //  initFormData();
        },)
        watch(formData,(nv,ov)=>{
          // console.log(`表单更新`,{
          //   nv:JSON.stringify(nv),
          //   ov:JSON.stringify(ov),
          // })
          ctx.emit("input",nv)
          ctx.emit("change",nv)
        },{deep:true})
        const handleSubmit= async ()=>{
        let valite = await formRef.value.validate()
          console.log(`表单提交`,formData.value,{valite})

         let res = await  ctx.emit('submit',formData.value)
         console.log(`提交成功-内部`,res)
        }
        const handleCancel= ()=>{
          resetForm();
          ctx.emit('cancel',formData.value)
        }
        /** 重置表单 */
        const resetForm  = ()=>{
          for(let key in formData.value){
             let fieldInfo = props.structure.find(item=>key === item.key);
             const defaultValue = defaultValueDic[fieldInfo!.type || 'text' ]
            //  console.log(`正在重置${key},相关配置=>`,{key,fieldInfo,defaultValue})
             formData.value[key] = defaultValue

            
          }

        };
        // exposed({initFormData})
        return {formRef,formData,formStructure:props.structure,handleSubmit,handleCancel}
        
    },
})
