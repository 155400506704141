



















































































import Dictionary from "@/common/dictionary";
import ListQuerytor from "@/common/ListQuerytorTs";
import { $post } from "@/http/axios";
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import ElFormPlus, { FormStructureItem } from "./el-form-plus/index.vue";

type TableScope = {
  $index:number,
  row:TableRow,
}
type TableColumnClickCallbackParams = {value:any,row:Object,index:number,column:TableColumn,instance:Object}
export type TableReadyCallbackParams = {querytor:ListQuerytor}


type SelectOptions = {
  label:string,
  value:string | number,
}[]
/**
 * 表格搜索栏配置项
 * */
export type TableSearchOptionItem = {
  label:string,
  key:string,
  type:'text' | 'select' ,
  options?:SelectOptions 
}
export type TableColumn = {
  prop?:string,
  label:string,
  width?:number,
  fixed?:boolean | 'left' | 'right',
  /**
   * 是否可过滤 [可选]  默认```false```, 可传入布尔值 或者  配置型(与搜索栏配置项相同格式) 
   * 当传入为布尔值时则会自动生成 ```type```为```text```的```TableSearchOptionItem```
   */
  filterable?:boolean | TableSearchOptionItem,
  type?:'actions',
  align?:'left' | 'right' | 'center'
  buttons?:Array<TableActionButton>,
  click?:(params:TableColumnClickCallbackParams)=>void,
  formatter?:((value:any,row:Object,index:number)=>any) | Dictionary

}

export type TableRow = {[key:string]:any}
export type TableActionClickCallbackContext = {
  /** 刷新表格数据 */
  refresh:()=>void
}

export type TableActionButton = { 
  label:string,
  type?:'primary' | 'default' | 'danger' | 'warning' | 'text',
  disabled?:boolean |((row:TableRow)=>boolean),
  hidden?:boolean | ((row:TableRow)=>boolean),
  /** 内置操作指令 */
  action?:'DETAIL' | 'EDIT' | 'DELETE' | 'ADD',
  /** 自定义主键 */
  primaryKey?:string,
  click?:(row:TableRow,ctx:TableActionClickCallbackContext)=>void
}
export type TableApis = {
  /** 列表加载接口 */
  list?:string,
  /** 详情获取接口 */
  detail?:string,
  /** 数据删除接口 */
  remove?:string,
  /** 新增/编辑接口 */
  save?:string
}
export default defineComponent({
    name: "ElTablePlus",
    components: { ElFormPlus },
    props: {
        title: {
            type: String
        },
        /**
         * 自动加载
         */
        autoLoad:{
          type:Boolean,
          default:true
        },
        apis:{
          type:Object as PropType<TableApis>,
          default:()=>({
            list:'',
            detail:'',
            remove:'',
            save:''
          })
        },
        /**
         * 表格数据加载API
         * */
        api: String,
        /**
         * 表格数据删除API
         * */
        removeApi: String,
        formStructure:{
           type:Array as PropType<FormStructureItem[]>,
           default:()=>[]
        },
        formLabelPosition:{
           type:String as PropType<'left' | 'right' | 'top'>,
           default:'left'
        },
        formLabelWdith:{
           type:String,
           default:'auto'
        },
        
        formName:{
          type:String,
          default:"新增"
        },
        /**
         * 每页数据条数
         * */
        size: {
            type: Number,
            default: 5
        },
        /**
         * 操作列宽度
         * */
        operationColumnWidth: {
            type: [String, Number],
            default: 150
        },
        /**
         * 表格列配置
         * */
        columns: {
            type: Array as PropType<TableColumn[]>,
            default: () => {
                return [];
            }
        },
        /**
         * 表格是否显示多选框
         */
        selection: {
            type: Boolean,
            default: false
        },
        /**
         * 表格是否显示序号列
         */
        showIndex: {
            type: Boolean,
            default: true
        },
        /**
         * 序号列 表头显示文字
         */
        showIndexText: {
            type: String,
            default: "序号"
        },
        /**
         * 搜索栏配置
         */
        searchOptions: {
            type: Array as PropType<TableSearchOptionItem[]>,
            default: () => {
                return [
                // {label:'文本筛选',key:'test',type:'text'},
                // {label:'下拉筛选',key:'test2',type:'select',options:[]},
                ];
            }
        },
        /**
         * 按钮栏配置传入
         */
        buttons: {
            type: Array as PropType<TableActionButton[]>,
            default: () => {
                return [
                // { label:'新增',type:'primary',click:()=>{}},
                ];
            }
        }
    },
    setup(props, ctx) {
        let instance: any = {};
        let searchForm = ref<{
            [key: string]: any;
        }>({});
        const listQuerytor = ref<ListQuerytor>(new ListQuerytor());
        const searchFormRef = ref();
        /**
         * 搜索事件触发
         */
        const handleSearch = () => {
            console.log(`触发搜索`, searchForm.value);
            page.value = 1;
            loadData();
        };
        /**
         * 重置搜索栏
         */
        const handleRestSearchForm = () => {
            for (let key in searchForm.value) {
                searchForm.value[key] = "";
            }
            handleSearch();
        };
        const hasOperationSlot = computed(()=>!!ctx.slots.operation);
        /**
         * 列配置项 转 搜索栏配置项
         * 
         */
        const  columnToSearchOption:(column:TableColumn)=>TableSearchOptionItem  = ({label,prop,filterable,formatter})=>{
          if(!filterable)return {} as TableSearchOptionItem; 
          if(typeof filterable === 'boolean'){
            return formatter instanceof Dictionary ?  {label, key:prop!,type:'select',options:formatter.parseSelectOptions()} :  {label, key:prop!,type:'text'} 
          }
          return {
            label:filterable.label || label!,
            key:filterable.key || prop!,
            type:filterable.type,
            options:formatter instanceof Dictionary ? formatter.parseSelectOptions() : filterable.options
          } 
         
        }
        let  searchFields  = ref<TableSearchOptionItem[]>([])
        // (()=>{
        //   return props.searchOptions.concat(props.columns.filter(item=>Boolean(item.filterable)).map(columnToSearchOption).filter(item=>item.label));

        // }) 
        onMounted(async () => {
            for(let column of props.columns ){
               if(column.formatter instanceof Dictionary){
                 await column.formatter.init()
               }
            }
            console.log("onMounted", { props,ctx });
            if (props.searchOptions.length) {
                searchForm.value = Object.fromEntries(props.searchOptions.map(item => ([item.key, ""])));
            }
            listQuerytor.value.config({ api: props.api || props.apis.list, size: props.size });
            //  listQuerytor.value = 
            initTable();
        });
        let tableData = ref([]);
        let loading = ref(false);
        let dataTotal = ref(0);
        let page = ref(1);
        let selectedRows = ref<TableRow[]>([]);
        instance.selectedRows = selectedRows;
        const handleSelectionChange = (rows: Array<TableRow>) => {
            console.log(`表格选中事件`, rows);
            selectedRows.value = rows;
        };
        instance.selectedRows = selectedRows;
        /**
         * 单元格点击事件
         * */
        const handleTableValueClick = ({ row, $index }: TableScope, column: TableColumn) => {
            const value = row[column.prop!];
            if (column.click && column.click instanceof Function) {
                column.click({ value, row, index: $index, column, instance });
            }
            console.log(`单元格点击`, { value, row, $index, column });
        };
        const computedTableValue = (scope: TableScope, column: TableColumn) => {
            const { row, $index } = scope;
            const value = row[column.prop!];
            /**
             * 如果传入外部格式化参数
             */
            if (column.formatter ) {
              /**
               * 如果传入回调方法
               */
              if(column.formatter instanceof Function){
                return column.formatter(value, row, $index) || "-";
              }
              /**
               * 如果传入字典
               */
              if(column.formatter instanceof Dictionary){
                return column.formatter.get(value)
              }
            }
            else {
                return value || "-";
            }
        };
        const initTable = () => {
            console.log(`表格初始化`, props.api);
            ctx.emit('ready',{querytor:listQuerytor.value})
            searchFields.value = props.searchOptions.concat(props.columns.filter(item=>Boolean(item.filterable)).map(columnToSearchOption).filter(item=>item.label))
            if (props.autoLoad &&( props.api || props.apis.list)) {
                loadData();
            }
        };
        const refresh = () => {
            loadData(page.value);
        };
        const loadData = async (page = 1) => {
            // const condition = encodeURIComponent(JSON.stringify(searchForm.value));
            listQuerytor.value!.setCondition(searchForm.value).query();
        };
        let currentOperatingRow = ref<TableRow>()
        const handleButtonsTabbarItemClick = (button: TableActionButton,row?:TableRow) => {
            if(row){
              currentOperatingRow.value= row;
            }
            type BuiltinActions = {
              [key in ("DETAIL" | "EDIT" | "DELETE" | 'ADD')]: (row:TableRow,button: TableActionButton) => any | ((row:TableRow,edit?: boolean,button?:TableActionButton) => any);
              };
            console.log(`按钮栏点击事件`, button, row,ctx.root);
            /**
             * 内置事件  通过传入 button.action 触发
             * */
            const builtinActions:BuiltinActions = {
                DETAIL:(row)=>handleShowDetail(row,false,button),
                EDIT:(row)=>handleShowDetail(row,true),
                DELETE:(row)=>handleRemove(row.id),
                ADD:()=>handleShowAdd(),
            };
            if(builtinActions[button.action!] && builtinActions[button.action!] instanceof Function) {
                builtinActions[button.action!](row!,button)
            }
            if (button.click && button.click instanceof Function) {
               
                button.click(row!,{refresh});
            }
        };
      
        /**
         * 详情弹窗相关
         */
        let  showDetailDialog = ref(false);
        let detailForm = ref<{[key:string]:any}>({})
        /** 详情表单是否只读 */
        let detailFormReadonly = ref(false)
        const handleShowDetail = async (row?:TableRow,edit?:boolean,button?:TableActionButton) =>{
          const primaryKey = (button && button!.primaryKey )? button!.primaryKey : 'id'
          if(row && row[primaryKey]){
            let {result} = await $post(props.apis.detail,{id:row[primaryKey]});
            console.log(`详情获取`,result)
            detailForm.value ={...result,...row} ;
          }
          showDetailDialog.value = true
          detailFormReadonly.value = !edit
          console.log(`查看详情`,row)
        }
        const handleShowAdd = ()=>{
          detailFormReadonly.value  = false;
          showDetailDialog.value = true
        }
        const handleDetailFormCancel = ()=>{
           currentOperatingRow.value = undefined;
           for(let key in detailForm.value){
             detailForm.value[key] = ''
           }
           showDetailDialog.value =false;
        }
        const handleDetailFormSave = async ()=>{
          let saveData = {...detailForm.value}
          if(currentOperatingRow.value && currentOperatingRow.value!.id){
            saveData.id= currentOperatingRow.value!.id
          }
          console.log(`保存数据`,saveData)
          // return
            let {success} = await $post(props.apis.save,saveData);
            if(success){
              ctx.root.$message.success('保存成功')
              handleDetailFormCancel();
              refresh();
            }
        }
        /**
         * 数据删除
         */
        const handleRemove = async (id:number)=>{
           await ctx.root.$confirm("是否确认删除","删除",{type:'warning',confirmButtonText:'删除',cancelButtonText:'取消'})
           let {success} = await $post(props.apis.remove,{id})
           if(success){
              ctx.root.$message.success("删除成功");
              refresh()
           }
       }
       /** 计算按钮是否隐藏 */
       const computedActionsButtonHidden = (item:TableActionButton,row:TableRow)=>{
         if(item.hidden instanceof Function){
            return item.hidden(row) 
         }
         return !!item.hidden;
       }

       /** 计算按钮是否隐藏 */
       const computedActionsButtonDisabled = (item:TableActionButton,row:TableRow)=>{
         if(item.disabled instanceof Function){
            return item.disabled(row) 
         }
         return !!item.disabled;
       }


        return {
            hasOperationSlot,
            //搜索栏相关
            searchForm,
            searchFormRef,
            searchFields,
            handleSearch,
            handleRestSearchForm,
            //按钮栏相关
            handleButtonsTabbarItemClick,computedActionsButtonHidden,computedActionsButtonDisabled,
            //表格相关
            tableData,
            loading,
            currentOperatingRow,
            handleSelectionChange,
            refresh,
            computedTableValue,
            handleTableValueClick,
            //查询器
            listQuerytor,
            //分页器相关
            dataTotal,
            page,
            //详情弹窗
            showDetailDialog,detailFormReadonly,detailForm,handleDetailFormCancel,handleDetailFormSave,handleShowDetail,
            //数据删除
            handleRemove

        };
    },
})

